import React from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import errorIcon from "../../../../assets/icons/png/22px/error.png";
import EmailPreference from "../../../Common/EmailPreference";
import SubscribeForm from "../../../Common/SubscribeForm/SubscribeForm";

class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        pageId: "",
        dbName: "",
        fname: "",
        lname: "",
        postcode: "",
        email: "",
        fields: [
          { name: "fname", error: false, message: "" },
          { name: "lname", error: false, message: "" },
          { name: "email", error: false, message: "" },
          { name: "postcode", error: false, message: "" },
        ]
      },
      emailPrefResp: {
        IsSuccessful: false,
        Message: "",
        ApiResponse: null
      },
      message: "",
      error: false,
      success: false,
      modalOpen: false,
      // Subscribe recaptcha
      subscribeVerifiedRecaptcha: false,
      subscribeExpiredRecaptcha: false,
      subscribeRecaptchaLoaded: false,
    };
  }

  subscribeRecaptchaLoaded = () => {
      console.log("Successful Load Recaptcha - Subscribe");
      this.setState({ subscribeRecaptchaLoaded: true });
  };

  subscribeRecaptchaExpired = () => {
      this.setState({
          subscribeVerifiedRecaptcha: false,
          subscribeExpiredRecaptcha: true
      });
  };

  subscribeRecaptchaVerifyResponse = response => {
      if (response) {
          this.setState({
              subscribeVerifiedRecaptcha: true,
              subscribeExpiredRecaptcha: false
          });
      } else {
          this.setState({ subscribeVerifiedRecaptcha: false });
      }
  };
  
  validateFormat = value => {
    let re = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$");
    return re.test(String(value).toLowerCase());
  };

  componentDidMount() {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let pageId = contextInfo.value;
    let dbName = contextInfo.dataset.targetDb;

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        pageId: pageId,
        dbName: dbName,
        fname: "",
        lname: "",
        postcode: "",
        email: "",
      }
    });
  }

  componentDidUpdate() {
    if (this.state.modalOpen) {
      const modalWrapper = document.querySelector('.modal-wrapper')
      if (modalWrapper) {
        setTimeout(() => { modalWrapper.classList.add('modal-active') }, 1000)
      }
    }
  }

  subscribe = async () => {
    const url = `/api/sitecore/subscription/getuser`;
    const { SubscribeEnableRecaptcha } = this.props;
    const { pageId, dbName, email: emailAddress } = this.state.formData

    const data = { pageId, dbName, emailAddress }

    if (this.state.formData.email !== "") {

      if (SubscribeEnableRecaptcha && SubscribeEnableRecaptcha.value && !this.state.subscribeVerifiedRecaptcha) {
        const recaptchaWrapper = document
          .querySelector(".newsletter-wrapper > .row > .col > .form-recaptcha");
          recaptchaWrapper.classList.add("is-required");

        this.setState({ ...this.setState, subscribeExpiredRecaptcha: true })
        this.setState({
          ...this.state,
          message: "",
          error: false,
          success: false,
          modalOpen: false
        });
      } else if (!this.validateFormat(this.state.formData.email)) {
        this.setState({
          ...this.state,
          message: "Please enter a valid email address",
          error: true,
          success: false,
          modalOpen: false
        });
      } else {
        let response = await axios({
          method: "POST",
          url,
          data: data
        });

        if (response && response.data) {
          let success, error, modalOpen = false
          let message = ''
          if (response.data.IsSuccessful) {
            success = true
            modalOpen = true
          }
          else {
            error = true
            message = response.data.Message
          }
          this.setState({
            ...this.state,
            message,
            error,
            success,
            modalOpen
          });
          document.getElementsByTagName("body")[0].classList.add("modal-open");
        }
      }

    } else {
      this.setState({
        ...this.state,
        message: "Please enter a valid email address",
        error: true,
        success: false
      });
    }
  }

  handleEmailChange = e => {
    let message = "";
    let error = false;

    if (this.state.error && !this.validateFormat(e.target.value)) {
      message = "Please enter a valid email address";
      error = true;
    }

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        email: e.target.value
      },
      message: message,
      error: error
    });
  };

  handlePreferencesResponse = (success, data, submitted) => {

    if (data.ApiResponse === 2 || data.ApiResponse === 5) {
      let fieldArrIndex = data.ApiResponse === 5 ? 3 : 2
      let fieldArr = this.state.formData.fields
      fieldArr[fieldArrIndex].error = true
      fieldArr[fieldArrIndex].message = data.Message
      this.setState({
        ...this.state,
        success,
        submitted,
        emailPrefResp: { ...data },
        formData: { ...this.state.formData, fields: fieldArr }
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, email: "", fname: "", lname: "", postcode: "" },
        success,
        submitted,
        emailPrefResp:
          { ...data },
      })
      if (success) {
        const modalWrapper = document.querySelector('.modal-wrapper')
        setTimeout(() => { modalWrapper.classList.remove('modal-active') }, 1000)
        setTimeout(() => {
          this.setState({ modalOpen: false })
          document.getElementsByTagName("body")[0].classList.remove("modal-open");
        }, 1500)
      }
    }
  };

  handleOnClose = e => {
    this.setState({
      success: false,
      modalOpen: false,
      formData: {
        ...this.state.formData,
        fname: "",
        lname: "",
        postcode: "",
        fields: [
          { name: "fname", error: false, message: "" },
          { name: "lname", error: false, message: "" },
          { name: "email", error: false, message: "" },
          { name: "postcode", error: false, message: "" },
        ]
      }
    });
    document.getElementsByTagName("body")[0].classList.remove("modal-open");
  };

  updateFieldsState = obj => {
    this.setState({ ...this.state, formData: { ...this.state.formData, ...obj } })
  }

  render() {
    const {
      EmailPlaceholder = null,
      CentreFormButton = null,
      CentreAgreement = null,
      EmailPreferenceModalTitle = null,
      EmailPreferenceModalDescription = null,
      EmailPreferenceModalButton = null,
      Interests = null,
      GenderGroupLabel = null,
      GenderOptions = null,
      FirstNameLabel = null,
      LastNameLabel = null,
      EmailLabel = null,
      PostcodeLabel = null,
      SubscribeEnableRecaptcha = null,
      SubscribePublicKey = null,
      EmailEnableRecaptcha = null,
      EmailPublicKey = null,
    } = this.props;

    const { pageId, dbName, email, fname, lname, postcode, fields } = this.state.formData || {};
    const { message, error, modalOpen } = this.state || {};
    const { subscribeVerifiedRecaptcha, subscribeExpiredRecaptcha } = this.state;

    const labels = {
      FirstNameLabel,
      LastNameLabel,
      EmailLabel,
      PostcodeLabel
    }

    const fieldStates = { fname, lname, email, postcode }
    return (
      <div className="newsletter-wrapper">
        {EmailPlaceholder &&
          EmailPlaceholder.value &&
          CentreFormButton &&
          CentreFormButton.value && (
            <div className="row">
              <div className="col col-12 col-lg-9 col-md-7">
                <div className="form-group">
                  <input
                    type="text"
                    id="footerNewsletterEmail"
                    className={
                      "form-control form-control-lg" +
                      (message ? " box-message" : "") +
                      (error ? " box-error" : "")
                    }
                    required
                    onChange={e => {
                      this.handleEmailChange(e);
                    }}
                    value={email}
                  />
                  <label
                    className="form-control-placeholder"
                    htmlFor="footerNewsletterEmail"
                  >
                    <Text field={EmailPlaceholder} />
                  </label>
                  {error && (
                    <img
                      src={errorIcon}
                      className="notify-indicator"
                      alt="notify-indicato"
                    />
                  )}
                  {message !== "" && (
                    <label
                      className={
                        (message ? "text-message" : "") +
                        (error ? " text-error" : "")
                      }
                    >
                      {message}
                    </label>
                  )}
                </div>
                {
                    SubscribeEnableRecaptcha &&
                    SubscribeEnableRecaptcha.value &&
                    SubscribePublicKey &&
                    SubscribePublicKey.value && (
                        <div
                            className={
                                "form-group form-recaptcha " +
                                (subscribeVerifiedRecaptcha ? "verifiedRecaptcha " : " ") +
                                (subscribeExpiredRecaptcha ? "is-required" : " ")
                            }
                        >
                            <ReCAPTCHA
                                size="normal"
                                sitekey={
                                    SubscribePublicKey && SubscribePublicKey.value
                                        ? SubscribePublicKey.value
                                        : "6LeqAfYUAAAAAGK1oZ1NzfVUFeC4kqJOe16XklGg"
                                }
                                onChange={this.subscribeRecaptchaVerifyResponse}
                                onExpired={this.subscribeRecaptchaExpired}
                                asyncScriptOnLoad={this.subscribeRecaptchaLoaded}
                            />
                            <div className="form-control-validation">
                                <label className="text-error">
                                    ReCaptcha is required
                                </label>
                            </div>
                        </div>
                    )}
              </div>
              <div className="col col-12 col-md-3">
                <button
                  className="newsletter-btn"
                  onClick={() => {
                    this.subscribe();
                  }}
                >
                  <Text field={CentreFormButton} />
                </button>
              </div>
            </div>
          )}
        {CentreAgreement && CentreAgreement.value && (
          <div className="row">
            <div className="col newsletter-cb">
              <RichText field={CentreAgreement} />
            </div>
          </div>
        )}

        {modalOpen && (
          <div className="modal-wrapper">
            <div className="container">
              <i className="icon icon-close" onClick={this.handleOnClose} />
              {fields.length &&
                <div className="modal-subscribe-form">
                  <div className="modal-subscribe-form__header">

                    <Text field={EmailPreferenceModalTitle} />
                  </div>
                  <div className="modal-subscribe-form-group-container">
                    <SubscribeForm fields={fields} fieldsStates={fieldStates} setFields={this.updateFieldsState} fieldsLabels={labels} />

                  </div>
                </div>
              }
              <EmailPreference
                PageID={pageId}
                DBName={dbName}
                EmailAddress={email}
                FirstName={fname}
                LastName={lname}
                Postcode={postcode}
                Description={EmailPreferenceModalDescription}
                ButtonName={
                  EmailPreferenceModalButton && EmailPreferenceModalButton.value
                    ? EmailPreferenceModalButton.value
                    : "Done"
                }
                Interests={Interests}
                GenderDescription={GenderGroupLabel}
                GenderOptions={GenderOptions}
                RadioGroupName={"Newsletter-Gender"}
                IsModal={true}
                EmailPreferenceResponse={(success, data, submitted) => {
                  this.handlePreferencesResponse(success, data, submitted)

                }}
                Container={'newsletter-wrapper'}
                Endpoint={'signup-footer'}
                EnableRecaptcha={EmailEnableRecaptcha}
                RecaptchaPublicKey={EmailPublicKey}
                // EnableRecaptcha={SubscribeEnableRecaptcha}
                // RecaptchaPublicKey={SubscribePublicKey}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Newsletter;
