import React, { Component } from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import HeaderImageFormat from "../../../../Common/HeaderImageFormat";

class NavMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenuTarget: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.activeMenuTarget &&
      prevState.activeMenuTarget != this.state.activeMenuTarget
    ) {
      prevState.activeMenuTarget.checked = false;
    }
  }

  handleMainNavClick = (activeTarget, displayNameValue) => {
    this.props.onShowNav(displayNameValue);
    this.setState({
      activeMenuTarget: activeTarget
    });
  };

  buildMenu = (navigations, isSimpleNav) => {
    const menu = navigations.map((navItem, index) => {
      let subMenu = "";
      let mainNav = "";
      const {
        DisplayName,
        Components,
        SecondaryNavigations,
        NavLink,
      } = navItem
      if (isSimpleNav) {
        if (Components && Components.length > 0) {
          subMenu = Components.map(componentItem => {
            const { ComponentName, Links } = componentItem || {};
            if (
              ComponentName &&
              ComponentName.value === "NavLinkList" &&
              Links.length > 0
            ) {
              return Links.map((secondaryItem, secIndex) => {
                const { LinkName, LinkUrl } = secondaryItem;
                return (
                  <div
                    key={"sec_" + secIndex}
                    className={
                      "secondary-nav" +
                        (this.state.activeMenuTarget.id === "_nav_" + index)
                        ? " active"
                        : ""
                    }
                  >
                    <Link
                      field={{ href: LinkUrl.value }}
                      className={"secondary-menu-item"}
                    >
                      <Text field={LinkName} />
                    </Link>
                  </div>
                );
              });
            }
          });
        }
      } else if (SecondaryNavigations && SecondaryNavigations.length > 0) {
        subMenu = SecondaryNavigations.map((secondaryItem, secIndex) => {
            return (
              <div
                key={"sec_" + secIndex}
                className={
                  "secondary-nav" +
                    (this.state.activeMenuTarget.id === "_nav_" + index)
                    ? " active"
                    : ""
                }
              >
                <Link
                  field={{ href: secondaryItem.LinkUrl.value }}
                  className={"secondary-menu-item"}
                >
                  <Text field={secondaryItem.DisplayName} />
                </Link>
              </div>
            );
          }
        );
      }

      if (NavLink && NavLink.value && NavLink.value.href) {
        mainNav =
          <div key={"pri_" + index} className={"main-nav"}>
            <a
              className="drop"
              {...NavLink.value}
              href={NavLink.value.href + NavLink.value.querystring}
            >
              <Text field={DisplayName} />
            </a>
          </div>
      } else {
        mainNav =
          <div key={"pri_" + index} className={"main-nav"}>
            <input
              id={"_nav_" + index}
              type="checkbox"
              onClick={e => this.handleMainNavClick(e.currentTarget, DisplayName.value)}
            />
            <label className="drop" htmlFor={"_nav_" + index}>
              <Text field={DisplayName} />
            </label>
            <div className="secondary-menu-group">{subMenu}</div>
          </div>
      }
      return mainNav;
    });
    return <div>{menu}</div>;
  };

  render() {
    const {
      navigations,
      onCloseMobileNav,
      centreLogo,
      isSimpleNav,
      logoLayout
    } = this.props;
    return (
      <div className="mobile-nav-container">
        <div className="mobile-nav-wrapper">
          <div className="container">
            <div className="header-container">
              <div className="logo">
                <HeaderImageFormat
                  logo={centreLogo}
                  uniqueKey="centre-logo-mobile"
                  isSticky={true}
                  simpleNav={isSimpleNav}
                  isGeneric={true}
                  logoLayout={logoLayout}
                />
              </div>
              <i onClick={onCloseMobileNav} className="icon icon-close" />
            </div>
            <div className="mobile-nav-content">
              {this.buildMenu(navigations, isSimpleNav)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NavMobile;
