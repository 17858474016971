import React, { Component } from "react";
import { Text, Placeholder } from "@sitecore-jss/sitecore-jss-react";
import TagManager from "react-gtm-module";
import md5 from 'md5';
import NavComponent from "../../../Feature/Header/Navigation/NavComponent";
import NavMobile from "../../../Feature/Header/Navigation/NavMobile";
import axios from "axios";
import UniversalBanner from "../../../Common/UniversalBanner";
import HeaderImageFormat from "../../../Common/HeaderImageFormat";
import LazyLoading from "../../../Common/LazyLoading";
import {
  disableBodyScroll,
  enableBodyScroll
} from "../../../../helper/ScrollHelper";

class FeatureHeaderPageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: false,
      showNav: false,
      showMobileNav: false,
      activeNav: null,
      activeSub: null,
      activeContent: [],
      activeSubList: [],
      navigations: [],
      isSimpleNav: false
    };
  }

  componentDidMount() {
    // initialize top menu item based from layout service
    const { UseSimpleHeader, Navigations } = this.props.fields;

    let isSimpleNav = false;

    if (UseSimpleHeader && UseSimpleHeader.value) {
      isSimpleNav = true;
    }

    if (document) {
      if (Navigations && Navigations.length) {
        let navigations = Navigations.map(x => {
          return { DisplayName: { value: x.fields.DisplayName.value } };
        });
        this.setState({
          ...this.state,
          navigations: navigations,
          isSimpleNav
        });
      }

      this.loadNavigationData();
    }

    if (window) {
      if (isSimpleNav) {
        window.addEventListener("resize", this.handleWindowResize);
      }
    }
  }

  handleWindowResize = () => {
    if (window.outerWidth >= 992) {
      this.showMobileNav(false);
      if (this.state.showSearch) {
        this.setState({ showSearch: false, showNav: false });
      }
      const newHeaderWrapper = document.querySelector(
        ".pageheader-navbar-wrapper"
      );
      if (newHeaderWrapper) {
        newHeaderWrapper.style.height = "auto";
        newHeaderWrapper.style.height = newHeaderWrapper.clientHeight + "px";
      }

      if (document) {
        let containerMargin = 0,
          navColWidth = 0,
          navLinkLeft = 0,
          leftPos = 0;

        const jsHeaderContainer = document.querySelector(
          ".js-header-container"
        );
        const jsNavbarNav = document.querySelector(".js-navbar-nav");
        const jsNavContent = document.querySelector(".nav-content.active");
        let jsNavItem = "";
        let jsNavLinkList = "";

        if (jsHeaderContainer) {
          containerMargin = window
            .getComputedStyle(jsHeaderContainer)
            .getPropertyValue("margin-left")
            .replace("px", "");
          navColWidth = jsHeaderContainer.clientWidth / 2;
        }

        if (jsNavbarNav) {
          jsNavItem = jsNavbarNav.querySelector(".nav-item.active");
          if (jsNavItem) {
            navLinkLeft = jsNavItem.getBoundingClientRect().x;
          }
        }

        if (jsNavContent) {
          jsNavLinkList = jsNavContent.querySelector(".js-nav-link-list");
          leftPos = navLinkLeft - containerMargin - navColWidth;
          if (jsNavLinkList) {
            jsNavLinkList.style.left = leftPos + "px";
          }
        }
      }
    }
    // else {
    //   this.hideOverlay();
    // }
  };

  adjustNavHeight = (isSimpleNav) => {
    if (isSimpleNav) {
      if (window.outerWidth >= 992) {
        const newHeaderWrapper = document.querySelector(
          ".pageheader-navbar-wrapper"
        );
        if (newHeaderWrapper) {
          newHeaderWrapper.style.height = newHeaderWrapper.clientHeight + "px";
        }
      }
    }
  }

  loadNavigationData = () => {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let me = this;
    const pageId = contextInfo.value;
    const dbName = contextInfo.dataset.targetDb;

    // url = `/api/sitecore/navigation/getnavigation?pageId=${pageId}&dbName=${dbName}`;
    axios({
      url: `/api/sitecore/navigation/getnavigation`,
      method: "GET",
      params: {
        pageId: pageId,
        dbName: dbName
      }
    })
      .then(responseRaw => {
        let response = responseRaw.data ? responseRaw.data : null;

        if (response && response.data.Navigations) {
          me.setState(
            {
              ...me.state,
              navigations: response.data.Navigations
            },
            () => {
              if (me.state.showNav) {
                me.getSubList();
              }
            }
          );
        }
      })
      .catch(error => {
        // just handle error
      });
  };

  handleFocus = () => {
    if (document) {
      document.querySelector("#consumer-search-bar").focus();
    }
  };

  handleClearSearch = () => {
    if (document) {
      document.querySelector(".searchbar .search-cancel") &&
        document.querySelector(".searchbar .search-cancel").click();
    }
  };

  showNav = navItem => {
    this.adjustNavHeight(this.state.isSimpleNav);

    if (this.state.activeNav !== navItem) {
      this.setState(
        {
          showNav: true,
          activeNav: navItem,
          activeContent: []
        },
        this.getSubList
      );
    }
  };

  showMobileNav = show => {
    if (this.state.showMobileNav !== show) {
      this.setState({
        showMobileNav: show
      });
    }
  };

  getSubList = async () => {
    console.log('======== getSubList ========');
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    const pageId = contextInfo.value;
    const dbName = contextInfo.dataset.targetDb;
    const { navigations, isSimpleNav, activeNav } = this.state;
    if (!isSimpleNav) {
      let top = navigations.find(x => x.DisplayName.value === activeNav);

      // Check if we need to load secondary nav data
      if (top && top.SecondaryNavIds && top.SecondaryNavIds.length && (!top.SecondaryNavigations || !top.SecondaryNavigations.length)) {
        try {
          this.setState({
            isLoadingSecondary: true
          });

          let secondaryApiUrl = `/api/sitecore/navigation/getsecondarynavigation?a=1&pageId=${pageId}&dbName=${dbName}`;
          top.SecondaryNavIds.forEach(navId => {
            secondaryApiUrl += `&SecondaryNavIds=${navId}`;
          });
          let secondaryData;

          if (!secondaryData) {
            // Make API call if no cached data
            try {
              const { data } = await axios({
                url: secondaryApiUrl,
                method: "GET",
              });
              secondaryData = data.data.SecondaryNavigations;
            } catch (e) {
              // sub nav fetch error
              console.error('SUB NAV ERROR!!!!!');
              console.error(e);
            }
          }

          // Update the navigation item with secondary data
          top["SecondaryNavigations"] = secondaryData;

          // Update navigations in state with new data
          const updatedNavigations = navigations.map(nav => 
            nav.DisplayName.value === activeNav ? {...nav, SecondaryNavigations: secondaryData} : nav
          );

          this.setState({
            navigations: updatedNavigations,
            isLoadingSecondary: false
          });

        } catch (error) {
          console.error('Error loading secondary navigation:', error);
          this.setState({
            isLoadingSecondary: false
          });
          return;
        }
      }

      if (top && top.SecondaryNavigations) {
        let subList = top.SecondaryNavigations;
        const temp = subList.map(item => {
          return {
            name: item.DisplayName.value,
            url: item.LinkUrl.value
          };
        });

        this.setState(
          {
            activeSubList: temp,
            activeSub: temp.length ? temp[0].name : ''
          },
          this.getSubContent
        );
      }
    }
  };

  hideOverlay = () => {
    enableBodyScroll();
    this.setState({
      showSearch: false,
      showNav: false,
      activeNav: null,
      activeContent: [],
      activeSubList: []
    });
  };

  setSub = sub => {
    if (this.state.activeSub !== sub) {
      this.setState(
        {
          activeSub: sub
        },
        this.getSubContent
      );
    }
  };

  getColumnSize = col => {
    return col === "25"
      ? "3"
      : col === "50"
        ? "6"
        : col === "75"
          ? "9"
          : col === "100"
            ? "12"
            : "";
  };

  getSubContent = () => {
    const content = [];

    let top = this.state.navigations.find(
      x => x.DisplayName.value === this.state.activeNav
    ).SecondaryNavigations;

    let second = top.find(y => y.DisplayName.value === this.state.activeSub);

    if (second) {
      let componentList = second.Components;
      let columnList = second.Layout.value.split("-");

      let column = [];
      column[0] = componentList.filter(x => x.ColumnPosition.value === "1");
      column[1] = componentList.filter(x => x.ColumnPosition.value === "2");
      column[2] = componentList.filter(x => x.ColumnPosition.value === "3");

      column.map((item, index) => {
        if (item.length > 0) {
          let colSize = this.getColumnSize(
            columnList[item[0].ColumnPosition.value - 1]
          );
          content.push(
            <div
              key={`compId-${index}`}
              className={`col-${colSize} order-${item[0].ColumnPosition.value}`}
            >
              {item.map((component, index) => {
                return (
                  <div key={`rowID-${index}`} className="nav-component">
                    <NavComponent
                      type={component.ComponentName.value}
                      item={component}
                      centre={this.props.params.activeCentre}
                      size={colSize}
                    />
                  </div>
                );
              })}
            </div>
          );
        }
      });

      this.setState({
        activeContent: content
      });
    }
  };

  renderComplexNavContent = () => {
    let complexNavLayout = "";

    if (this.state.showNav) {
      complexNavLayout = (
        <div className={`nav-container ${this.state.showNav ? "active" : ""}`}>
          {this.state.isLoadingSecondary ? (
            <LazyLoading />
          ) : (
          <>
            <div className="nav-sub">
              <div className="container">
                  {this.state.activeSubList &&
                    this.state.activeSubList.map((item, index) => (
                    <div
                      key={`myId-${index}`}
                      className="sub-item"
                      onMouseEnter={() => {
                        this.setSub(item.name);
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "viewSubNav",
                            subNavActive: item.name
                          },
                          dataLayerName: "dataLayer"
                        });
                      }}
                    >
                      <a
                        href={item.url}
                        className={`btn btn-link  ${this.state.activeSub === item.name ? "active" : ""
                          }`}
                      >
                        <Text field={{ value: item.name }} />
                      </a>
                    </div>
                  ))}
              </div>
            </div>
            <div className="nav-content">
              <div className="container">
                <div className="row">{this.state.activeContent}</div>
              </div>
            </div>
          </>
          )}
        </div>
      );
    }

    return complexNavLayout;
  };

  renderSimpleNavContent = () => {
    const { activeNav, navigations, showNav } = this.state;

    let simpleNavLayout = "";
    simpleNavLayout = (
      <div className={`nav-container ${showNav ? "active" : ""}`}>
        <div className="container">
          {navigations.map((item, index) => {
            const { DisplayName, Components } = item;
            let leftPos = 0;

            if (
              activeNav &&
              activeNav.toLowerCase() === DisplayName.value.toLowerCase()
            ) {
              if (document) {
                let containerMargin = 0,
                  navColWidth = 0,
                  navLinkLeft = 0;
                const jsHeaderContainer = document.querySelector(
                  ".js-header-container"
                );
                const jsNavbarNav = document.querySelector(".js-navbar-nav");

                if (jsHeaderContainer) {
                  containerMargin = window
                    .getComputedStyle(jsHeaderContainer)
                    .getPropertyValue("margin-left")
                    .replace("px", "");
                  navColWidth = jsHeaderContainer.clientWidth / 2;
                }
                if (jsNavbarNav) {
                  navLinkLeft = jsNavbarNav
                    .querySelectorAll(".nav-item")
                  [index].getBoundingClientRect().x;
                }

                leftPos = navLinkLeft - containerMargin - navColWidth;
              }
            }

            return (
              <div
                key={DisplayName.value + index}
                className={`nav-content 
              ${activeNav &&
                    activeNav.toLowerCase() === DisplayName.value.toLowerCase()
                    ? "active"
                    : ""
                  }

            `}
              >
                <div className="row">
                  <div className="col col-lg-6">
                    {Components &&
                      Components.length > 0 &&
                      Components.map((item, index) => {
                        const { ComponentName, ComponentTitle } = item;

                        let componentLayout = "";

                        if (
                          ComponentName &&
                          ComponentName.value !== "NavLinkList"
                        ) {
                          componentLayout = (
                            <div key={ComponentName.value + index}>
                              {ComponentName.value === "NavPopularTimes" && (
                                <div className="nav-populartimes__header">
                                  <Text field={ComponentTitle} />
                                </div>
                              )}

                              <NavComponent
                                type={ComponentName.value}
                                item={item}
                                centre={this.props.params.activeCentre}
                                size={"12"}
                              />
                            </div>
                          );
                        }
                        return componentLayout;
                      })}
                  </div>
                  <div className="col col-lg-6">
                    {Components &&
                      Components.length > 0 &&
                      Components.map((item, index) => {
                        const { ComponentName, Links } = item;

                        let componentLayout = "";

                        if (
                          ComponentName &&
                          ComponentName.value === "NavLinkList"
                        ) {
                          componentLayout = (
                            <div
                              className="js-nav-link-list"
                              key={ComponentName.value + index}
                              style={{ left: leftPos + "px" }}
                            >
                              <NavComponent
                                type={ComponentName.value}
                                item={Links}
                              />
                            </div>
                          );
                        }
                        return componentLayout;
                      })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
    return simpleNavLayout;
  };

  renderNavLink = (navigations, activeNav, isSimpleNav, showNav) => {
    return (
      navigations &&
      navigations.length > 0 &&
      navigations.map((item, index) => {
        const {
          DisplayName,
          NavLink,
          SecondaryNavigations,
          SecondaryNavIds,
          Components
        } = item;

        let navLinkLayout = "";

        if ((SecondaryNavIds && SecondaryNavIds.length) || (SecondaryNavigations && SecondaryNavigations.length > 0) || (Components && Components.length > 0)) {
          navLinkLayout =
            <li
              key={`liId-${index}`}
              className={`nav-item ${activeNav === DisplayName.value ? "active" : ""
                }`}
            >
              <a
                className="nav-link"
                onClick={() => {
                  disableBodyScroll();
                  this.showNav(DisplayName.value);
                }}
                onMouseEnter={() => {
                  if (isSimpleNav && showNav) {
                    disableBodyScroll();
                    this.showNav(DisplayName.value);
                  }
                }}
              >
                <Text field={{ value: DisplayName.value }} />
              </a>
            </li>
        }
        else if (NavLink && NavLink.value && NavLink.value.href) {
          navLinkLayout =
            <li
              key={`liId-${index}`}
              className={`nav-item ${activeNav === DisplayName.value ? "active" : ""
                }`}
            >
              <a
                className="nav-link"
                {...NavLink.value}
                href={NavLink.value.href + NavLink.value.querystring}
                onMouseEnter={() => {
                  if (isSimpleNav && showNav) {
                    disableBodyScroll();
                    this.showNav(DisplayName.value);
                  }
                }}
              >
                <Text field={DisplayName} />
              </a>
            </li>
        }
        return navLinkLayout
      })
    )
  }

  render() {
    const { CentreLogo, BackButtonLink, UseSimpleHeader, LogoLayout } = this.props.fields || {};
    const {
      navigations,
      activeNav,
      showNav,
      showSearch,
      showMobileNav,
      isSimpleNav
    } = this.state;
    return (
      <div className={`${UseSimpleHeader && UseSimpleHeader.value ? "simple-header" : ""}`}>
        <UniversalBanner data={this.props.fields} isNavShown={showNav} />
        {isSimpleNav && (
          <div
            className={`search-bar-main container ${showSearch ? "search-active" : ""
              }`}
          >
            <Placeholder
              name="jss-searchmodal-right"
              rendering={this.props.rendering}
              isSimple={true}
              show={showSearch}
            />
          </div>
        )}
        <div className={`pageheader-navbar-wrapper ${showNav ? "active" : ""}`}>
          <div
            className={`js-header-container container ${showNav ? "fixed-top" : ""
              }`}
          >
            <nav className="navbar navbar-expand-lg ">
              <span className={`${showNav ? "" : "hide"}`}>
                <HeaderImageFormat
                  logo={CentreLogo}
                  uniqueKey="centre-logo-page-header"
                  isSticky={showNav}
                  simpleNav={showNav}
                  logoLayout={LogoLayout ? LogoLayout.value : 'Default'}
                  isGeneric={true}
                />
              </span>
              <div className="mobile-nav">
                {(!showSearch || (isSimpleNav && showNav)) && (
                  <button
                    className="nav-link search"
                    onClick={() => {
                      if (isSimpleNav) {
                        this.setState({
                          showSearch: true
                        });
                        this.handleFocus();
                      } else {
                        this.setState({ showSearch: true });
                      }
                    }}
                    aria-label="nav link search"
                  >
                    <i className="icon icon-outlined-search" />
                  </button>
                )}
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={() => {
                    this.showMobileNav(true);
                  }}
                >
                  <i className="icon icon-outlined-menu" />
                </button>
              </div>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul
                  className={`js-navbar-nav navbar-nav ml-auto ${showNav ? "active" : ""
                    }`}
                >
                  {this.renderNavLink(navigations, activeNav, isSimpleNav, showNav)}
                  <li className="nav-item">
                    {((!isSimpleNav && (showNav || showSearch)) ||
                      (isSimpleNav && showSearch)) && (
                        <button className="nav-link" onClick={this.hideOverlay}>
                          <i className="icon icon-close" />
                        </button>
                      )}

                    {((!showSearch && isSimpleNav) ||
                      (!isSimpleNav && !showSearch && !showNav)) && (
                        <i
                          className="icon icon-outlined-search"
                          onClick={() => {
                            if (isSimpleNav) {
                              this.adjustNavHeight(isSimpleNav);
                              this.setState({
                                showSearch: true,
                                showNav: true,
                                activeNav: null
                              });
                              this.handleFocus();
                            } else {
                              this.setState({ showSearch: true });
                            }
                          }}
                        />
                      )}
                  </li>
                </ul>
              </div>
            </nav>
            {BackButtonLink &&
              BackButtonLink.value &&
              (BackButtonLink.value.href || BackButtonLink.value.text) && (
                <div
                  className={`pageheader-navbar-wrapper__backbtn ${showNav || showMobileNav ? "is-hidden" : ""
                    }`}
                >
                  {BackButtonLink.value.href ? (
                    <div>
                      <a
                        href={BackButtonLink.value.href}
                        title={BackButtonLink.value.title}
                        target={BackButtonLink.value.target}
                      >
                        <i className="icon icon-arrow-left" />
                        <span>{BackButtonLink.value.text}</span>
                      </a>
                    </div>
                  ) : (
                    BackButtonLink.value.text
                  )}
                </div>
              )}
          </div>

          {isSimpleNav
            ? this.renderSimpleNavContent()
            : this.renderComplexNavContent()}

          {showSearch && !isSimpleNav && (
            <div className={`search-container ${showNav ? "active" : ""}`}>
              <div className="search-wrapper">
                <div className="container">
                  <div className="clearfix">
                    <i
                      onClick={this.hideOverlay}
                      className="icon icon-close float-right"
                    />
                  </div>
                  <div className="search-content">
                    <Placeholder
                      name="jss-searchmodal-right"
                      rendering={this.props.rendering}
                      isSimple={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {showMobileNav && (
            <NavMobile
              onCloseMobileNav={() => {
                this.showMobileNav(false);
              }}
              navigations={this.state.navigations}
              centreLogo={CentreLogo}
              isSimpleNav={isSimpleNav}
              logoLayout={LogoLayout.value}
              onShowNav={this.showNav}
            />
          )}
        </div>

        {showNav && isSimpleNav && (
          <div className="js-close-nav" onMouseEnter={this.hideOverlay} />
        )}
      </div>
    );
  }
}

export default FeatureHeaderPageHeader;
